<template>
    <div  style="user-select:none;">
        <div id="videoPlayr" style="width: 100vw;height: 40vh;"></div>
        <div class="direction" style="user-select:none;">
            <p class="direction-box">方向调节</p>
            <div class="main" @click="guanbi()">
                <img src="../assets/download/guanbi.png" alt="" style="width: 50px;height: 50px;">
            </div>
        </div>
        <div class="box-a" style="user-select:none;" >
            <div class="box-y">
                <div @touchstart="upleft()"  v-if="userpass" style="user-select:none;">
                    <img src="../assets/img/upleft.png" alt=""  class="user">
                </div>
                <div @touchend="upleft2()"   v-if="userpass" style="user-select:none;">
                    <img src="../assets/img/upleft2.png" alt=""  class="user">
                </div>
                <div v-if="!miss"  @touchstart="up()" style="user-select:none;">
                    <img src="../assets/img/up.png" alt=""  class="user">
                </div>
                <div v-if="miss"  @touchend="up2()" style="user-select:none;">
                    <img src="../assets/img/up2.png" alt="" class="user">
                </div>
                <div v-if="poss"  alt=""  @touchstart="upreight()" style="user-select:none;">
                    <img src="../assets/img/upreight.png"   class="user">
                </div>
                <div  v-if="poss" @touchend="upreight2()" style="user-select:none;">
                    <img src="../assets/img/upreight2.png"  alt="" class="user">
                </div>
            </div>
        </div>
        <div class="box-a" style="user-select:none;">
            <div class="box">
                <div @touchstart="left()" v-if="!userpass2" style="user-select:none;">
                    <img src="../assets/img/left.png"  alt="" class="user">
                </div>
                <div @touchend="left2()" v-if="userpass2" style="user-select:none;">
                    <img src="../assets/img/left2.png"  alt="" class="user">
                </div>
                <div v-if="!miss2" @click="restorationno()" style="user-select:none;">
                    <img src="../assets/img/restorationno.png"  alt="" class="user">
                </div>
                <div v-if="miss2" style="user-select:none;">
                    <img src="../assets/img/restoration.png"   alt="" class="user">
                </div>
                <div  @touchstart="right()" v-if="!poss2" style="user-select:none;">
                    <img src="../assets/img/right.png" alt=""   class="user">
                </div>
                <div @touchend="right2()" v-if="poss2" style="user-select:none;">
                    <img src="../assets/img/right2.png" alt=""  class="user">
                </div>
            </div>
        </div>
        <div class="box-a" style="user-select:none;">
            <div class="box-y">
                <div  v-if="userpass3" @touchstart="leftlower()" style="user-select:none;">
                    <img src="../assets/img/leftlower.png" alt="" class="user" >
                </div>
                <div v-if="userpass3" @touchend="leftlower2()" style="user-select:none;">
                    <img src="../assets/img/leftlower2.png"  alt="" class="user" >
                </div>
                <div v-if="!miss3" @touchstart="below()" style="user-select:none;">
                    <img src="../assets/img/below.png" alt=""  class="user" >
                </div>
                <div v-if="miss3" @touchend="below2()" style="user-select:none;">
                    <img src="../assets/img/below2.png" alt=""  class="user" >
                </div>
                <div v-if="poss3" @touchstart="reightlower()" style="user-select:none;">
                    <img src="../assets/img/reightlower.png"  alt="" class="user" >
                </div>
                <div  v-if="poss3" @touchend="reightlower2()" style="user-select:none;">
                    <img src="../assets/img/reightlower2.png"  alt="" class="user" >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import EZUIKit from "../components/ezuikit"
    import {getYsyToken,piginfo,monitoringstart,monitoringstop,monitoringpreset} from "../api/index"
    export default{
        data(){
            return{
                id:'',
                url :'',
                direction:"",
                access_id:'',
                userpass:false,
                miss:false,
                poss:false,
                userpass2:false,
                miss2:false,
                poss2:false,
                userpass3:false,
                miss3:false,
                poss3:false,
                stop:false
            }
        },
        mounted(){
            this.id = this.$route.query.p_id
            this.access_id = this.$route.query.access_id
            this.getTokenYSY();
        },
        methods:{
            guanbi(){
                window.location.href = 'uniwebview://Close?test=Close'
            },
            //获取token 
            async getTokenYSY() {
				let res = await getYsyToken();
                    this.accessToken = res.data.data.accessToken;
                    this.piginfo()
			},
            //加载视频
            async piginfo(){
                let data = {
                    p_id: this.id
                }
                let res = await piginfo(data);
                // this.url = "ezopen://open.ys7.com/"+ res.data.data.deviceSerial+'/'+res.data.data.channelNo+'.live'
                this.url = res.data.data.monitor_url 
                var playr;
                playr = new EZUIKit.EZUIKitPlayer({
                    id: 'videoPlayr', // 视频容器ID
                    accessToken: this.accessToken,
                    url: this.url,
                    // template: 'simple', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版; theme-可配置主题；
                    plugin: ['talk'],                       // 加载插件，talk-对讲
                });
                console.log(this.url)
            },

            //调整监控
            async monitoringstart(){
                let data = {
                    access_id:this.access_id,
                    ls_id: this.id,
                    direction :this.direction
                }
                let res = await monitoringstart(data)
                console.log(res,"调整")
                if(res.data.code == 200){
                    this.stop = true
                }else{
                    this.stop = false
                }
                this.$toast(res.data.message);
            },
            //调整预制点
            async monitoringpreset(){
                let data = {
                    access_id:this.access_id,
                    ls_id: this.id,
                }
            let res = await monitoringpreset(data)
            this.$toast(res.data.message);
            setTimeout(()=>{
                this.miss2 = false
            },300)
            },
            //结束监控
            async monitoringstop(){
                let data = {
                    access_id:this.access_id,
                    ls_id: this.id,
                    direction :this.direction   
                }
                let  res = await monitoringstop(data)
                console.log(res,'结束')
                this.$toast(res.data.message);
            },
            
            upleft(){
                this.direction = 4
                this.userpass = true
                this.monitoringstart()
            },
            upleft2(){
                this.userpass = false
                if(this.stop){
                    this.monitoringstop()
                }
            },
            up(){
                this.direction = 0
                this.miss = true
                this.monitoringstart()
            },
            up2(){
                this.miss = false
                if(this.stop){
                    this.monitoringstop()
                }
            },
            upreight(){
                this.direction = 6
                this.poss = true
                this.monitoringstart()
            },
            upreight2(){
                this.poss = false
                if(this.stop){
                    this.monitoringstop()
                }
            },
            left(){
                this.direction = 2
                this.userpass2 = true
                this.monitoringstart()
            },
            left2(){
                this.userpass2 = false
                if(this.stop){
                    this.monitoringstop()
                }
            },
            restorationno(){
                this.miss2 = true
                this.monitoringpreset()
                console.log(11)
            },
            right(){
                this.direction = 3
                this.poss2 = true
                this.monitoringstart()
            },
            right2(){
                this.poss2 = false
                if(this.stop){
                    this.monitoringstop()
                }
            },
            leftlower(){
                this.direction = 5
                this.userpass3 = true
                this.monitoringstart()
            },
            leftlower2(){
                this.userpass3 = false
                if(this.stop){
                    this.monitoringstop()
                }
            },
            below(){
                this.direction = 1
                this.miss3 = true
                this.monitoringstart()
            },
            below2(){
                this.miss3 = false
                if(this.stop){
                    this.monitoringstop()
                }
            },
            reightlower(){
                this.direction = 7
                this.poss3 = true
                this.monitoringstart()
            },
            reightlower2(){
                this.poss3 = false
                if(this.stop){
                    this.monitoringstop()
                }
            }

      }
    }
</script>


<style>
    body,html{
        background-color: #FBE5BA;
        user-select:none;
    }
    .van-toast {
         user-select:none !important;
        -moz-user-select:none;
        -webkit-user-select:none;
        -ms-user-select:none;
        -khtml-user-select:none;
        -o-user-select:none;
    }
    .van-toast__text{
        user-select:none !important;
        -moz-user-select:none;
        -webkit-user-select:none;
        -ms-user-select:none;
        -khtml-user-select:none;
        -o-user-select:none;
    }
    .direction{
        color: #945614;
        margin-bottom: 80px;
        display: flex;
        justify-content: space-between;
    }

    .direction-box{
        padding: 20px;    
        font-size: 30px;
        margin-top: 10px;
    }
    .user{
        width: 120px;
        pointer-events:none;
        user-select:none;
    }   
    .main{
        /* position: fixed; */
        margin: 20px;
    }

    .box-a{
        display: flex;
        justify-content: center;
    }
    .box{
        width: 60%;
        display: flex;
        justify-content: space-between;
        margin-top: 35px;
    }
    .box-y{
        width: 50%;
        display: flex;
        justify-content: space-around;
        margin-top: 35px;
    }
</style>